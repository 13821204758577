export { apiStatusComputedFactory } from './apiStatusComputedFactory'
export { getResourceRequestQuery } from './getResourceRequestQuery'
export {
  getTablePaginationParams,
  getInfScrollPaginationParams,
} from './getTablePaginationParams'
export { withAsync } from './withAsync'
export { default as apiStatus } from './apiStatus'

export function stripExtraDelimiterAtTheEnd(str, delimiter = '&') {
  return str?.endsWith(delimiter) ? str.slice(0, -delimiter.length) : str
}
