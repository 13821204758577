import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Import locale messages
import enUs from './locales/en-us.json'
import enRecoverable from './locales/en-recoverable.json'

Vue.use(VueI18n)

// Create i18n instance
const i18n = new VueI18n({
  locale: 'en-US', // Set default locale
  fallbackLocale: 'en-US', // Fallback locale
  messages: {
    'en-US': enUs,
    'en-recoverable': enRecoverable,
  },
})

export default i18n
