import { mergeDuplicateParams } from '@/utils/helpers'

import api from './Api'
import { getTablePaginationParams, getResourceRequestQuery } from './helpers'

const defaultResources = ['driveStep', 'driveState.drive', 'mailing']

const ENDPOINTS = {
  actions: (id = '') => (id ? `/actions/${id}` : `/actions`),
  actionIds: '/actionIds',
  bulkActions: '/bulk/actions',
  undoAction: (id) => `${ENDPOINTS.actions(id)}/actions/undo`,
  markComplete: (id) => `${ENDPOINTS.actions(id)}/actions/markComplete`,
  opts: {
    actionType: (type = '') => (type ? `actionType=${type}` : ''),
    prospectId: (id) => `prospectId=${id}`,
    showManual: ['actionTypeExclusion=drive_auto_email', 'state=in_progress'],
    showAutomated: [
      'actionType=drive_auto_email,auto_email',
      'state=in_progress,scheduled,failed,needs_attention,needs_attention_template_missing',
    ],
    showOutbox: [
      'actionType=drive_auto_email,auto_email,drive_manual_email,manual_email',
      'state=throttled,scheduled,needs_attention',
      'mailingState=throttled,scheduled,failed,needs_attention,needs_attention_template_missing',
      'mailingDelivered=false',
    ],
    showScheduled: [
      'actionType=drive_auto_email,auto_email,drive_manual_email,manual_email',
      'state=throttled,scheduled,needs_attention',
      'mailingState=scheduled',
      'mailingDelivered=false',
    ],
    showFailed: [
      'actionType=drive_auto_email,auto_email,drive_manual_email,manual_email',
      'state=throttled,scheduled,needs_attention',
      'mailingState=failed',
      'mailingDelivered=false',
    ],
    showThrottled: [
      'actionType=drive_auto_email,auto_email,drive_manual_email,manual_email',
      'state=throttled,scheduled,needs_attention',
      'mailingState=throttled',
      'mailingDelivered=false',
    ],
    showMergeTagIssues: [
      'actionType=drive_auto_email,auto_email,drive_manual_email,manual_email',
      'state=throttled,scheduled,needs_attention',
      'mailingState=needs_attention,needs_attention_template_missing',
      'mailingDelivered=false',
    ],
    allTeam: () => 'allTeam=1',
  },
}

export default {
  getActions(options = {}) {
    let path = ENDPOINTS.actions()
    let pathParams = []

    const {
      showAutomated,
      showManual,
      showOutbox,
      showScheduled,
      showMergeTagIssues,
      showThrottled,
      showFailed,
      actionType,
      resourceRequests,
      filtersQuery,
      mustSendFilterQuery,
      prospectId,
      allTeam,
      skipAbort,
    } = options

    // Fetch actions for a specific prospect
    if (prospectId) pathParams.push(ENDPOINTS.opts.prospectId(prospectId))

    // Include auto or manual actions
    if (showAutomated)
      pathParams = pathParams.concat(ENDPOINTS.opts.showAutomated)
    else if (showManual)
      pathParams = pathParams.concat(ENDPOINTS.opts.showManual)
    else if (showOutbox)
      pathParams = pathParams.concat(ENDPOINTS.opts.showOutbox)
    else if (showScheduled)
      pathParams = pathParams.concat(ENDPOINTS.opts.showScheduled)
    else if (showMergeTagIssues)
      pathParams = pathParams.concat(ENDPOINTS.opts.showMergeTagIssues)
    else if (showThrottled)
      pathParams = pathParams.concat(ENDPOINTS.opts.showThrottled)
    else if (showFailed)
      pathParams = pathParams.concat(ENDPOINTS.opts.showFailed)

    // Include specific action type
    if (actionType) pathParams.push(ENDPOINTS.opts.actionType(actionType))
    if (filtersQuery && (showManual || mustSendFilterQuery))
      pathParams = pathParams.concat(filtersQuery.split('&') || [])

    // Merge actionTypeExclusion param
    pathParams = mergeDuplicateParams(pathParams, 'actionType=')
    pathParams = mergeDuplicateParams(pathParams, 'actionTypeExclusion=')

    // All users actions
    if (allTeam) pathParams.push(ENDPOINTS.opts.allTeam())

    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (resourceRequests)
      pathParams.push(getResourceRequestQuery(resourceRequests))
    else pathParams.push(getResourceRequestQuery(defaultResources))

    pathParams = pathParams.filter((i) => !!i?.trim()) // Remove any empty params
    if (pathParams.length) path += '?' + pathParams.join('&')

    let config
    if (!skipAbort) config = { abortKey: 'GET_ACTIONS' }

    return api.get(path, config)
  },

  getActionsByIds({ ids, resourceRequests, orderBy, skipAbort = false }) {
    let path = ENDPOINTS.actions()
    let pathParams = []

    pathParams.push(`id=${ids.join(',')}`)

    if (resourceRequests)
      pathParams.push(getResourceRequestQuery(resourceRequests))
    else pathParams.push(getResourceRequestQuery(defaultResources))

    if (orderBy) pathParams.push(`orderBy=${orderBy}`)

    // Since we're providing ids - All users actions
    pathParams.push(ENDPOINTS.opts.allTeam())

    pathParams = pathParams.filter((i) => !!i?.trim()) // Remove any empty params
    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, skipAbort ? {} : { abortKey: 'GET_ACTION_BY_IDS' })
  },

  // Returns an array of string uuids
  getActionIds(options = {}) {
    let path = ENDPOINTS.actionIds
    let pathParams = []

    const {
      showAutomated,
      showManual,
      showOutbox,
      showScheduled,
      showMergeTagIssues,
      showThrottled,
      showFailed,
      actionType,
      filtersQuery,
      mustSendFilterQuery,
      prospectId,
      allTeam,
      skipAbort,
      orderBy,
    } = options

    // Fetch actions for a specific prospect
    if (prospectId) pathParams.push(ENDPOINTS.opts.prospectId(prospectId))

    // Include auto or manual actions
    if (showAutomated)
      pathParams = pathParams.concat(ENDPOINTS.opts.showAutomated)
    else if (showManual)
      pathParams = pathParams.concat(ENDPOINTS.opts.showManual)
    else if (showOutbox)
      pathParams = pathParams.concat(ENDPOINTS.opts.showOutbox)
    else if (showScheduled)
      pathParams = pathParams.concat(ENDPOINTS.opts.showScheduled)
    else if (showMergeTagIssues)
      pathParams = pathParams.concat(ENDPOINTS.opts.showMergeTagIssues)
    else if (showThrottled)
      pathParams = pathParams.concat(ENDPOINTS.opts.showThrottled)
    else if (showFailed)
      pathParams = pathParams.concat(ENDPOINTS.opts.showFailed)

    // Include specific action type
    if (actionType) pathParams.push(ENDPOINTS.opts.actionType(actionType))
    if (filtersQuery && (showManual || mustSendFilterQuery))
      pathParams = pathParams.concat(filtersQuery.split('&') || [])

    // Merge actionTypeExclusion param
    pathParams = mergeDuplicateParams(pathParams, 'actionType=')
    pathParams = mergeDuplicateParams(pathParams, 'actionTypeExclusion=')

    // Since we're providing ids - All users actions
    if (allTeam) pathParams.push(ENDPOINTS.opts.allTeam())

    if (orderBy) pathParams.push(`orderBy=${orderBy}`)

    pathParams = pathParams.filter((i) => !!i?.trim()) // Remove any empty params
    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, skipAbort ? {} : { abortKey: 'GET_ACTION_IDS' })
  },

  async getActionById(id) {
    const path =
      ENDPOINTS.actions(id) + '?' + getResourceRequestQuery(defaultResources)
    return api.get(path)
  },

  undoAction(actionId) {
    return api.post(ENDPOINTS.undoAction(actionId))
  },

  markComplete(actionId, payload = null) {
    if (payload) api.post(ENDPOINTS.markComplete(actionId), payload)
    return api.post(ENDPOINTS.markComplete(actionId))
  },

  updateAction(actionId, payload) {
    return api.put(ENDPOINTS.actions(actionId), payload)
  },

  createAction(payload) {
    return api.post(ENDPOINTS.actions(), payload)
  },

  deleteAction(actionId) {
    return api.delete(ENDPOINTS.actions(actionId))
  },

  bulkCancelActions({ body, queryParams }) {
    let path = ENDPOINTS.bulkActions + (queryParams ? `?${queryParams}` : '')
    return api.put(path, {
      actions: body.actions,
      update: { state: 'incomplete' },
    })
  },
}
