import api from './Api'
import store from '@/store'
import { getFiltersQuery } from '@/services/stateful/FilterService'

const ENDPOINTS = {
  bulkProspects: `/bulk/prospects`,
  exportProspects: () => `${ENDPOINTS.bulkProspects}/actions/export`,
  toggleEmailOpt: () => `${ENDPOINTS.bulkProspects}/actions/toggleEmailOpt`,
  assignTags: () => `${ENDPOINTS.bulkProspects}/actions/assignTags`,
  assignUser: () => `${ENDPOINTS.bulkProspects}/actions/assignUser`,
  findTimezone: () => `${ENDPOINTS.bulkProspects}/actions/findTimezone`,
  checkDNC: () => `${ENDPOINTS.bulkProspects}/actions/dncCheck`,
  scheduleAction: () => `${ENDPOINTS.bulkProspects}/actions/storeAction`,
  update: () => `${ENDPOINTS.bulkProspects}/actions/update`,
  delete: () => `${ENDPOINTS.bulkProspects}/actions/delete`,
  deletePhoneNumbers: () =>
    `${ENDPOINTS.bulkProspects}/actions/deleteDncPhoneNumbers`,
  removeFromDialSession: () =>
    `${ENDPOINTS.bulkProspects}/actions/removeFromDialSession`,
  dispositionMultiManage: () =>
    `${ENDPOINTS.bulkProspects}/dispositionMultiManage`,
  storeDriveState: () => `${ENDPOINTS.bulkProspects}/actions/storeDriveState`,
  pauseDriveState: () => `${ENDPOINTS.bulkProspects}/actions/pauseDriveState`,
  resumeDriveState: () => `${ENDPOINTS.bulkProspects}/actions/resumeDriveState`,

  importPeople: () => `/bulkExclusion/crm/prospects/import`,
  importPeopleFromReport: (id) => `/bulkExclusion/crm/reports/${id}/import`,

  opts: {
    exclusions: (ids = []) => (ids.length ? `exclusions=${ids.join(',')}` : ``),
    wildcardTableSearch: (text) => `tableSearch=${text}`,
    orderBy: (val) => `orderBy=${val}`,
  },
}

function getSearchParams() {
  let search = store.getters['contacts/contactSearch']
  if (!search) return ''
  return ENDPOINTS.opts.wildcardTableSearch(search)
}

export default {
  // NEW APPROACH
  // All of these endpoints support both bulk and bulk exclusion
  // Body is used for any request specific data or for prospect selections in case of bulk request

  toggleEmailOpt({ queryParams, body }) {
    let path = ENDPOINTS.toggleEmailOpt()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams
      if (limit) pathParams.push(`limit=${limit}`)

      let searchParams = getSearchParams()
      if (searchParams) pathParams.push(searchParams)

      if (exclusionIds.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

      const filtersQuery = getFiltersQuery.get()
      if (filtersQuery) pathParams.push(filtersQuery)
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  assignTags({ queryParams, body }) {
    let path = ENDPOINTS.assignTags()
    let pathParams = []

    if (queryParams) {
      const {
        exclusionIds,
        limit,
        orderBy,
        queryType,
        reportId,
        lastDncCheck,
      } = queryParams

      if (lastDncCheck) {
        pathParams.push(`lastDncCheck[exact]=${lastDncCheck}`)
        pathParams.push('hasDncNumber=1')
      }
      // All other params are skipped if we have a lastDncCheck
      else {
        if (limit) pathParams.push(`limit=${limit}`)

        let searchParams = getSearchParams()
        if (searchParams) pathParams.push(searchParams)

        if (exclusionIds?.length)
          pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

        if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

        const filtersQuery = getFiltersQuery.get()
        if (filtersQuery) pathParams.push(filtersQuery)

        if (queryType) pathParams.push(`queryType=${queryType}`)

        if (reportId) pathParams.push(`reportId=${reportId}`)
      }
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  scheduleAction({ queryParams, body }) {
    let path = ENDPOINTS.scheduleAction()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams

      if (limit) pathParams.push(`limit=${limit}`)

      let searchParams = getSearchParams()
      if (searchParams) pathParams.push(searchParams)

      if (exclusionIds?.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

      const filtersQuery = getFiltersQuery.get()
      if (filtersQuery) pathParams.push(filtersQuery)
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  updateStatus({ queryParams, body }) {
    let path = ENDPOINTS.update()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy, lastDncCheck } = queryParams

      if (lastDncCheck) {
        pathParams.push(`lastDncCheck[exact]=${lastDncCheck}`)
        pathParams.push('hasDncNumber=1')
      }
      // All other params are skipped if we have a lastDncCheck
      else {
        if (limit) pathParams.push(`limit=${limit}`)

        let searchParams = getSearchParams()
        if (searchParams) pathParams.push(searchParams)

        if (exclusionIds?.length)
          pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

        if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

        const filtersQuery = getFiltersQuery.get()
        if (filtersQuery) pathParams.push(filtersQuery)
      }
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  findTimezone({ queryParams, body }) {
    let path = ENDPOINTS.findTimezone()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams

      if (limit) pathParams.push(`limit=${limit}`)

      if (exclusionIds?.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))
    }

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  checkDNC({ queryParams, body }) {
    let path = ENDPOINTS.checkDNC()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams

      if (limit) pathParams.push(`limit=${limit}`)

      if (exclusionIds?.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))
    }

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  assignUser({ queryParams, body }) {
    let path = ENDPOINTS.assignUser()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy, queryType, reportId } = queryParams
      if (limit) pathParams.push(`limit=${limit}`)

      let searchParams = getSearchParams()
      if (searchParams) pathParams.push(searchParams)

      if (exclusionIds.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

      const filtersQuery = getFiltersQuery.get()
      if (filtersQuery) pathParams.push(filtersQuery)

      if (queryType) pathParams.push(`queryType=${queryType}`)

      if (reportId) pathParams.push(`reportId=${reportId}`)
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  exportProspects({ queryParams, body }) {
    let path = ENDPOINTS.exportProspects()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams
      if (limit) pathParams.push(`limit=${limit}`)

      let searchParams = getSearchParams()
      if (searchParams) pathParams.push(searchParams)

      if (exclusionIds.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

      const filtersQuery = getFiltersQuery.get()
      if (filtersQuery) pathParams.push(filtersQuery)
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  deleteProspects({ queryParams, body }) {
    let path = ENDPOINTS.delete()
    let pathParams = []

    if (queryParams) {
      const { exclusionIds, limit, orderBy } = queryParams
      if (limit) pathParams.push(`limit=${limit}`)

      let searchParams = getSearchParams()
      if (searchParams) pathParams.push(searchParams)

      if (exclusionIds.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

      const filtersQuery = getFiltersQuery.get()
      if (filtersQuery) pathParams.push(filtersQuery)
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },

  deleteDncPhoneNumbers(lastDncCheck, users) {
    let path = ENDPOINTS.deletePhoneNumbers()
    let pathParams = []

    if (lastDncCheck) pathParams.push(`lastDncCheck[exact]=${lastDncCheck}`)

    pathParams.push('hasDncNumber=1')

    if (users?.length)
      pathParams.push(`owner=${users.map((user) => user.id).join(',')}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },

  removeDNCNumbersFromDialSession(lastDncCheck, users) {
    let path = ENDPOINTS.removeFromDialSession()
    let pathParams = []

    if (lastDncCheck) pathParams.push(`lastDncCheck[exact]=${lastDncCheck}`)

    pathParams.push('hasDncNumber=1')

    if (users?.length)
      pathParams.push(`owner=${users.map((user) => user.id).join(',')}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },

  // OLD APROACH

  importPeople(
    exclusionIds = [],
    reportId = null,
    orderBy,
    matchToCrmOwners = false
  ) {
    let path = reportId
      ? ENDPOINTS.importPeopleFromReport(reportId)
      : ENDPOINTS.importPeople()
    let pathParams = []

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    if (matchToCrmOwners) pathParams.push('match_to_crm_owners=true')

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },

  dispositionMultiManage(
    exclusionIds = [],
    accountId = null,
    queryType = null,
    reportId = null,
    limit = null,
    orderBy = null
  ) {
    let path = ENDPOINTS.dispositionMultiManage()
    let pathParams = []

    if (accountId) pathParams.push(`accountId=${accountId}`)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    if (queryType) pathParams.push(`queryType=${queryType}`)

    if (reportId) pathParams.push(`reportId=${reportId}`)

    if (limit) pathParams.push(`limit=${limit}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  assignToDrive(
    driveId,
    exclusionIds = [],
    accountId = null,
    queryType = null,
    reportId = null,
    limit = null,
    orderBy = null
  ) {
    if (!driveId) return

    let path = ENDPOINTS.storeDriveState()
    let pathParams = []

    if (accountId) pathParams.push(`accountId=${accountId}`)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    if (queryType) pathParams.push(`queryType=${queryType}`)

    if (reportId) pathParams.push(`reportId=${reportId}`)

    if (limit) pathParams.push(`limit=${limit}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, {
      driveId,
    })
  },

  pauseDrives(exclusionIds = [], accountId = null, orderBy = null) {
    let path = ENDPOINTS.pauseDriveState()
    let pathParams = []

    if (accountId) pathParams.push(`accountId=${accountId}`)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },

  resumeDrives(exclusionIds = [], accountId = null, orderBy = null) {
    let path = ENDPOINTS.resumeDriveState()
    let pathParams = []

    if (accountId) pathParams.push(`accountId=${accountId}`)

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },
}
