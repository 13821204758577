import ProspectsApi from '@/services/api/Prospects'
import BulkProspectExclusionApi from '@/services/api/BulkProspectExclusion'
import { EventBus } from '@/services/event-bus'
import { getContactsWithActivity, transformCountValues } from '@/utils/helpers'
import { getTablePaginationParams } from '@/services/api/helpers'

// initial state
const state = () => ({
  contacts: [],
  contactsPagination: null,
  selectedContacts: [],
  excludedContactIds: [],
  selectedProspectStatus: null,
  selectionsLimit: null,
  selectionsLimitExclusions: [],
  contactSearch: '',
  contactStatus: '',
  showDeleted: false,

  // DNC
  dncContactsCount: 0,
  dncPhoneNumbersCount: 0,
  dncLastChecked: null,
})

// getters
const getters = {
  allContacts: (state) =>
    state.contacts && state.contacts.length
      ? state.contacts.map((contact) => ({
          ...contact,
          full_name: contact.full_name
            ? contact.full_name
            : contact.first_name || contact.last_name
            ? `${contact.first_name} ${contact.last_name}`.trim()
            : '(unknown)',
        }))
      : [],
  selectedContacts: (state) => state.selectedContacts,
  contactsPagination: (state) => state.contactsPagination,
  excludedContactIds: (state) => state.excludedContactIds,
  selectedProspectStatus: (state) => state.selectedProspectStatus,
  selectionsLimit: (state) => state.selectionsLimit,
  selectionsLimitExclusions: (state) => state.selectionsLimitExclusions,
  contactSearch: (state) => state.contactSearch,
  contactsLoading: (state) => state.contactStatus === 'loading',
  showDeleted: (state) => state.showDeleted,

  // DNC
  dncContactsCount: (state) => state.dncContactsCount,
  dncPhoneNumbersCount: (state) => state.dncPhoneNumbersCount,
  dncLastChecked: (state) => state.dncLastChecked,
}

// actions
const actions = {
  async fetchContacts({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      ProspectsApi.getProspects({
        ...options,
        showDeleted: state.showDeleted,
      })
        .then((resp) => {
          // Set selected contacts first so uncheck animation isn't visible
          if (options.selectAll) commit('setSelectedContacts', resp.data)
          else if (
            options.selectAllExclude &&
            state.excludedContactIds.length
          ) {
            const selectAllRespectExclude = resp.data.filter((resPerson) => {
              return !state.excludedContactIds.some(
                (excPerson) => excPerson === resPerson.id
              )
            })
            commit('setSelectedContacts', selectAllRespectExclude)
          }
          commit('setContacts', resp.data)
          commit('setContactsPagination', resp.pagination)
          commit('contactSuccess')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('contactError')
            reject(err)
          }
        })
    })
  },

  async delete({ commit }, { prospectId }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      ProspectsApi.deleteProspect(prospectId)
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: 'Successfully deleted',
              snackType: 'success',
            },
            {
              root: true,
            }
          )

          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue deleting, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },

  async toggleAutoScheduleActions({ commit }, { contact }) {
    return new Promise((resolve, reject) => {
      ProspectsApi.updateAutoScheduleActions(contact.id, {
        auto_schedule: !contact.auto_schedule,
      })
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: !contact.auto_schedule
                ? 'Auto schedule actions enabled'
                : 'Auto schedule actions disabled',
              snackType: 'success',
            },
            { root: true }
          )
          commit('updateContactProperty', {
            contactId: contact.id,
            property: 'auto_schedule',
            value: !contact.auto_schedule,
          })
          resolve(resp)
        })
        .catch((err) => {
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error updating auto schedule status',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
        .finally(() => {
          EventBus.$emit('auto-schedule-updated', contact.id)
        })
    })
  },

  async import({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      ProspectsApi.addProspectByExternalId(payload)
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: 'Successfully imported',
              snackType: 'success',
            },
            {
              root: true,
            }
          )
          EventBus.$emit('crm-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue importing, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },

  // BULK ACTIONS - Old Syntax
  importMessage({ commit }, { doIfConfirmed, doIfConfirmedParam }) {
    commit(
      'appModal/showModal',
      {
        title: 'Import contact?',
        text: 'Will be added to Symbo contacts.',
        doIfConfirmed: doIfConfirmed,
        doIfConfirmedParam: doIfConfirmedParam,
      },
      {
        root: true,
      }
    )
  },
  async bulkImportExclusion(
    { commit },
    {
      exclusions,
      reportId,
      tableOps,
      dontRefetchData,
      matchToCrmOwners = false,
    }
  ) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.importPeople(
        exclusions,
        reportId,
        getTablePaginationParams(tableOps, 'orderBy'),
        matchToCrmOwners
      )
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully imported',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} impored. Unable to import ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue importing, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }
          if (!dontRefetchData) EventBus.$emit('people-fetch-contacts', true)

          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue importing, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },

  // BULK ACTIONS - New Syntax
  deleteMessage({ commit }, { doIfConfirmed, doIfConfirmedParam }) {
    commit(
      'appModal/showModal',
      {
        title: 'Delete people?',
        text: 'Will be moved to the trash for 30 days and then deleted.',
        dangerModal: true,
        doIfConfirmed: doIfConfirmed,
        doIfConfirmedParam: doIfConfirmedParam,
      },
      {
        root: true,
      }
    )
  },
  async bulkDelete({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.deleteProspects({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully deleted',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} deleted. Unable to delete ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue deleting, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }

          EventBus.$emit('people-fetch-contacts', true)

          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue deleting, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )

          reject(err)
        })
    })
  },
  optOutMessage({ commit }, { doIfConfirmed, doIfConfirmedParam, optOut }) {
    const title = optOut ? 'Opt out people?' : 'Opt in people?'
    const text = optOut
      ? "Emails won't be sent to these people"
      : 'Unblock emails for these prospects'
    commit(
      'appModal/showModal',
      {
        title,
        text,
        dangerModal: true,
        doIfConfirmed: doIfConfirmed,
        doIfConfirmedParam: doIfConfirmedParam,
      },
      {
        root: true,
      }
    )
  },
  async bulkToggleEmailOpt({ commit, state }, { queryParams, body }) {
    const optDirection = body.optOut ? 'out' : 'in'
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.toggleEmailOpt({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Successfully Opted ${optDirection}`,
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} opted ${optDirection}. Unable to opt ${optDirection} ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue opting ${optDirection}, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }

          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue opting ${optDirection}, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkAssignTags({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.assignTags({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully assigned tags',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} assigned tags. Unable to assign tags ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue assigning tags try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue assigning tags, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkScheduleAction({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.scheduleAction({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount && !errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully scheduled actions',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount && errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Successfully scheduled actions for ${successCount} people. Unable to schedule for on ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (!successCount && errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue scheduling actions, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue scheduling actions, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkUpdateStatus({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.updateStatus({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount && !errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully updated status',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount && errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} people transitioned to next status. Unable to update on ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (!successCount && errorCount) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue updating status try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue updating status, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkComputeTimezones({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      BulkProspectExclusionApi.findTimezone({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: `Timezones computed successfully.`,
              snackType: 'success',
            },
            {
              root: true,
            }
          )
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue computing timezones. Try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkCheckDNC({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      BulkProspectExclusionApi.checkDNC({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          console.log('resp', resp)
          commit('setDncCheckResults', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue checking DNC, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkAssignUser({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      commit('contactRequest')
      BulkProspectExclusionApi.assignUser({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully assigned user',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} assigned. Unable to assign ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue assigning user, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue assigning user, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  async bulkExport({ commit, state }, { queryParams, body }) {
    return new Promise((resolve, reject) => {
      BulkProspectExclusionApi.exportProspects({
        queryParams: queryParams && {
          ...queryParams,
          tableOps: null,
          exclusionIds: queryParams.excludedContactIds?.length
            ? queryParams.excludedContactIds
            : state.excludedContactIds,
          orderBy: getTablePaginationParams(queryParams.tableOps, 'orderBy'),
        },
        body,
      })
        .then((resp) => {
          commit('resetContactSelections')
          resolve(resp)
        })
        .catch((err) => {
          commit(
            'snackbar/setSnack',
            {
              snack:
                'There was an issue exporting the contacts. Please try again.',
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  setContactsPagination(state, pagination) {
    state.contactsPagination = pagination
  },
  setContactsActivity(state, activityList) {
    state.contacts = getContactsWithActivity(
      state.contacts,
      transformCountValues(activityList)
    )
  },
  setSelectedContacts(state, selectedContacts) {
    state.selectedContacts = selectedContacts
  },
  setExcludedContactIds(state, excludedContactIds) {
    state.excludedContactIds = excludedContactIds
  },
  setSelectionsLimit(state, limit) {
    state.selectionsLimit = limit
  },
  setSelectionsLimitExclusions(state, limitExclusions) {
    state.selectionsLimitExclusions = limitExclusions
  },
  setSelectedProspectStatus(state, prospectStatus) {
    state.selectedProspectStatus = prospectStatus
  },
  setShowDeleted(state, showDeleted) {
    state.showDeleted = showDeleted
  },
  resetContactSelections(state) {
    state.selectedContacts = []
    state.excludedContactIds = []
    state.selectionsLimit = null
    state.selectionsLimitExclusions = []
  },
  setContactSearch(state, search) {
    state.contactSearch = search
  },
  contactRequest(state) {
    state.contactStatus = 'loading'
  },
  contactSuccess(state) {
    state.contactStatus = 'success'
  },
  contactError(state) {
    state.contactStatus = 'error'
  },
  updateContactProperty(state, { contactId, property, value }) {
    const index = state.contacts.findIndex((c) => c.id === contactId)
    if (index === -1) return
    const contact = state.contacts[index]
    state.contacts[index] = { ...contact, [property]: value }
  },

  // DNC
  setDncCheckResults(state, data) {
    state.dncContactsCount = data.dncProspectCount
    state.dncPhoneNumbersCount = data.dncPhoneNumbersCount
    // Slicing to get only yyyy-mm-dd HH:mm. Added to skip seconds cz api is returning yyyy-mm-dd HH:mm:ss
    state.dncLastChecked = data.lastDncCheck
  },
  resetDncCheckResults(state) {
    state.dncContactsCount = 0
    state.dncPhoneNumbersCount = 0
    state.dncLastChecked = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
