import Vue from 'vue'
import { DateTime } from 'luxon'

import router from '@/router'
import store from '@/store'
import ActionsApi from '@/services/api/Actions'
import { quickMenuServiceComputed } from '@/services/stateful/QuickMenuService'
import { EventBus } from '@/services/event-bus'
import { getLuxonObj } from '../../utils/dateHelpers'
import { getTablePaginationParams } from '../api/helpers'

const _orderBy = require('lodash/orderBy')
const _unionBy = require('lodash/unionBy')

const initState = {
  apiStatus: '',
  currentActionIndex: 0,
  actionFiltersQuery: '',
  isMiniQuickActionsBar: false,

  plannedActions: [],
  plannedActionsCount: 0,
  executingSelectedActions: false,

  isStaticActionFlow: false,
  considerProspectPriority: false,
  staticActionIds: [],

  scrollSettings: {
    allActionsLoaded: false,
    lastPage: false,
    page: 1,
    itemsPerPage: 15,
    sortBy: ['due_at'],
    sortDesc: [false],
  },
}

const initialState = Object.entries(initState).reduce(
  (newObj, [key, value]) => {
    newObj[key] = value
    return newObj
  },
  {}
)
const state = Vue.observable(initialState)

export const quickActionServiceComputed = {
  isMiniQuickActionsBar: {
    get: function () {
      return state.isMiniQuickActionsBar
    },
    set: function (newVal) {
      state.isMiniQuickActionsBar = newVal
    },
  },
  actionFiltersQuery: {
    get: function () {
      return state.actionFiltersQuery
    },
    set: function (newVal) {
      state.actionFiltersQuery = newVal
    },
  },
  plannedActions: {
    get: function () {
      return state.plannedActions
    },
    set: function (newVal) {
      state.plannedActions = newVal
    },
  },
  executingSelectedActions: {
    get: function () {
      return state.executingSelectedActions
    },
  },
  completedPlannedActions: {
    get: function () {
      return state.plannedActions.filter(
        (action) => action.state === 'completed'
      )
    },
  },
  toDoPlannedActions: {
    get: function () {
      return state.plannedActions.filter(
        (action) => action.state !== 'completed'
      )
    },
  },
  plannedActionsCount: {
    get: function () {
      return state.plannedActionsCount
    },
    set: function (newVal) {
      state.plannedActionsCount = newVal
    },
  },
  plannedActionsScrollSettings: {
    get: function () {
      return state.scrollSettings
    },
    set: function (newVal) {
      state.scrollSettings = newVal
    },
  },
  currentPlannedAction: {
    get: function () {
      if (
        state.plannedActions.length &&
        state.plannedActions[state.currentActionIndex]
      ) {
        return state.plannedActions[state.currentActionIndex]
      } else return {}
    },
  },
  plannedActionsApiStatus: {
    get: function () {
      return state.apiStatus
    },
  },
  plannedActionIndex: {
    get: function () {
      return state.currentActionIndex
    },
  },
  nextQuickActionExists: {
    get: function () {
      if (
        state.currentActionIndex === state.plannedActions.length - 1 &&
        state.scrollSettings.lastPage
      ) {
        return false
      } else return true
    },
  },
  prevQuickActionExists: {
    get: function () {
      return state.currentActionIndex > 0
    },
  },
  isViewingActiveAction: {
    get: function () {
      const currRoute = this.$route || router.currentRoute
      const currAction = state.plannedActions[state.currentActionIndex]
      if (
        currRoute.name === 'ContactView' &&
        currRoute.params.id === currAction?.prospect.id
      ) {
        return true
      } else return false
    },
  },
  isActionFlowActive: {
    get: function () {
      return state.plannedActions.length > 0
    },
  },
  isStaticActionFlow: {
    get: function () {
      return state.isStaticActionFlow
    },
    set: function (newVal) {
      state.isStaticActionFlow = newVal
    },
  },
  considerProspectPriority: {
    get: function () {
      return state.considerProspectPriority
    },
    set: function (newVal) {
      state.considerProspectPriority = newVal
    },
  },
  staticActionIds: {
    get: function () {
      return state.staticActionIds
    },
    set: function (newVal) {
      state.staticActionIds = newVal
    },
  },
}

export const getPlannedActionIndexFromId = (id) =>
  state.plannedActions.findIndex((action) => action.id === id)

export const setMiniQuickActionsBar = (newVal) => {
  state.isMiniQuickActionsBar = newVal
}

export const fetchAndSetStaticActionIds = async (filtersQuery) => {
  state.staticActionIds = await ActionsApi.getActionIds({
    orderBy: getTablePaginationParams(state.scrollSettings, 'orderBy'),
    showManual: true,
    filtersQuery,
  })
  return state.staticActionIds
}

export const startQuickActionService = async (
  plannedActions = [], // Selected action objects
  sucCallback,
  filtersQuery
) => {
  console.log('startQuickActionService', plannedActions, filtersQuery)
  state.actionFiltersQuery = filtersQuery
  state.executingSelectedActions = !!plannedActions.length
  if (plannedActions.length) {
    // only executing selected/planned actions
    state.plannedActions = plannedActions
    state.scrollSettings.lastPage = true
    state.scrollSettings.allActionsLoaded = true
    state.plannedActionsCount = plannedActions.length
  } else {
    // executing all/filtered actions
    await fetchQuickActions()
  }

  if (state.plannedActions.length) {
    navProspect(quickActionServiceComputed.currentPlannedAction.get())
    quickMenuServiceComputed.quickActionServiceActive.set(true)
    sendSnackMessage('Actions started!', 'success')
    sucCallback?.()
  } else {
    quickMenuServiceComputed.quickActionServiceActive.set(false)
    sendSnackMessage('No Actions to do !', 'error')
  }
}

export const initQuickActionService = async (
  plannedActions = [], // Selected action objects
  sucCallback,
  filtersQuery
) => {
  console.log('initQuickActionService', plannedActions, filtersQuery)
  state.actionFiltersQuery = filtersQuery
  state.executingSelectedActions = !!plannedActions.length
  if (plannedActions.length) {
    // Only executing selected/planned actions
    await startQuickActionService(plannedActions, sucCallback, filtersQuery)
  } else {
    // Show modal to select dynamic/static action flow mode
    EventBus.$emit('show-query-based-action-flow-dialog', {
      filtersQuery,
      sucCallback,
    })
  }
}

export const resetQuickActionService = () => {
  console.log('resetQuickActionService')
  quickMenuServiceComputed.quickActionServiceActive.set(false)
  sendSnackMessage('Actions completed!', 'success')

  // TODO: make reusable
  state.apiStatus = ''
  state.currentActionIndex = 0
  state.plannedActions = []
  state.plannedActionsCount = 0
  state.executingSelectedActions = false
  state.isStaticActionFlow = false
  state.staticActionIds = []
  state.considerProspectPriority = false
  state.scrollSettings = {
    allActionsLoaded: false,
    lastPage: false,
    page: 1,
    itemsPerPage: 15,
    sortBy: ['due_at'],
    sortDesc: [false],
  }
}

export const resetPlannedActions = () => {
  state.currentActionIndex = 0
  state.plannedActions = []
  state.executingSelectedActions = false
  state.scrollSettings.page = 1
  state.scrollSettings.lastPage = false
}

export const nextPlannedAction = () => {
  if (
    state.currentActionIndex === state.plannedActions.length - 1 &&
    !state.scrollSettings.lastPage
  ) {
    // load next page of actions and navigate
    state.scrollSettings.page++
    return new Promise((resolve) => {
      fetchQuickActions().then(() => {
        state.currentActionIndex++
        navProspect(quickActionServiceComputed.currentPlannedAction.get()).then(
          () => resolve()
        )
      })
    })
  } else if (state.currentActionIndex < state.plannedActions.length - 1) {
    // next action loaded, navigate
    state.currentActionIndex++
    return navProspect(quickActionServiceComputed.currentPlannedAction.get())
  }
}

export const prevPlannedAction = () => {
  if (state.currentActionIndex <= 0) return
  state.currentActionIndex--
  navProspect(quickActionServiceComputed.currentPlannedAction.get())
}

export const setPlannedAction = (actionId) => {
  const actionIndex = state.plannedActions.findIndex(
    (action) => action.id === actionId
  )
  if (actionIndex != -1) state.currentActionIndex = actionIndex
  navProspect(quickActionServiceComputed.currentPlannedAction.get())
}

export const markActionComplete = (actionId) => {
  if (!actionId) return

  let actionIdx = state.plannedActions.findIndex((i) => i.id === actionId)
  if (actionIdx !== -1) {
    const alreadyCompleted =
      state.plannedActions[actionIdx].state === 'completed'
    state.plannedActions.splice(actionIdx, 1, {
      ...state.plannedActions[actionIdx],
      state: 'completed',
      completed: true,
      completedAt: DateTime.now(),
    })
    if (!state.scrollSettings.allActionsLoaded) {
      fetchQuickActions()
    } else if (quickMenuServiceComputed.quickActionServiceActive.get()) {
      if (!alreadyCompleted) state.plannedActionsCount--
      if (!state.plannedActionsCount) resetQuickActionService()
    }
  }
  EventBus.$emit('mark-active-action-complete', actionId)
}

export const removeAction = (actionIndex) => {
  state.plannedActions.splice(actionIndex, 1)
  if (actionIndex === state.currentActionIndex) {
    // in case we're somehow removing the last item in the list
    if (state.currentActionIndex >= state.plannedActions.length) {
      state.currentActionIndex = state.plannedActions.length - 1
    }
  }
  state.plannedActionsCount--
}

export const removeActiveActionAndNavigateToNext = () => {
  state.plannedActions.splice(state.currentActionIndex, 1)
  // in case we're somehow removing the last item in the list
  if (state.currentActionIndex >= state.plannedActions.length) {
    state.currentActionIndex = state.plannedActions.length - 1
  }
  navProspect(quickActionServiceComputed.currentPlannedAction.get())
}

const transformResp = (reset = false, resp, prospectId = null) => {
  console.log('transformResp', resp)
  state.plannedActionsCount = resp.pagination.total
  state.apiStatus = 'success'
  if (
    !resp.data.length &&
    (state.plannedActions.length ||
      quickActionServiceComputed.completedPlannedActions.get()?.length)
  ) {
    resetQuickActionService()
  }

  if (reset) {
    // Override
    state.plannedActions = resp.data
  } else if (state.considerProspectPriority) {
    // New actions with prospect
    const uniqueWithProspect = resp.data.filter((a) => {
      return (
        a.prospect &&
        state.plannedActions.findIndex((i) => i.id === a.id) === -1
      )
    })
    let mergedAndSortedActions = orderArrayByScrollSettings([
      ...state.plannedActions,
      ...uniqueWithProspect,
    ])
    if (prospectId) {
      // Priority actions
      // Any new actions for the current prospect
      const priorityActions = mergedAndSortedActions.filter(
        (a) =>
          a.prospect.id === prospectId &&
          !a.completed &&
          a.id !== quickActionServiceComputed.currentPlannedAction.get()?.id
      )

      // Remove priority actions from random spots in the array
      // This keeps only actions without priority actions
      mergedAndSortedActions = mergedAndSortedActions.filter(
        (a) =>
          priorityActions.findIndex(
            (prospectAction) => prospectAction.id === a.id
          ) === -1
      )

      // Add priority actions back at appropriate spots in the array
      mergedAndSortedActions.splice(
        state.currentActionIndex + 1,
        0,
        ...priorityActions
      )
    }

    // Filter out any bad actions
    mergedAndSortedActions = mergedAndSortedActions.filter(
      (action) => !!action.state && !!action.id && !!action.due_at
    )
    state.plannedActions = mergedAndSortedActions
  } else {
    state.plannedActions = _unionBy(state.plannedActions, resp.data, 'id')
  }

  if (resp.pagination.total === 0 && resp.pagination.lastPage === 0) {
    state.scrollSettings.page = 1
    state.scrollSettings.lastPage = true
  } else if (resp.pagination.page === resp.pagination.lastPage) {
    state.scrollSettings.lastPage = true
  } else if (resp.pagination.page > resp.pagination.lastPage) {
    state.scrollSettings.page = resp.pagination.lastPage
    fetchQuickActions()
  }
}

export const fetchQuickActions = async (reset = false, pid = null) => {
  if (state.executingSelectedActions) return

  state.apiStatus = 'loading'
  const options = {
    ...state.scrollSettings,
    showManual: true,
    resourceRequests: [
      'prospect',
      'prospect.phoneNumbers',
      'driveStep',
      'driveState.drive',
    ],
    filtersQuery: state.actionFiltersQuery,
  }

  if (state.isStaticActionFlow) {
    // If actionsIds not loaded, load action ids
    // Get ids for all the actions that fall in the prospect query
    if (!state.staticActionIds?.length)
      await fetchAndSetStaticActionIds(state.actionFiltersQuery)

    // If actionIds loaded, fetch actions based on the loaded actionIds
    if (state.staticActionIds?.length) {
      // Get batch of 15 ids for the current page
      let { page, itemsPerPage } = state.scrollSettings
      let offset = (page - 1) * itemsPerPage
      let idsToQuery = state.staticActionIds.slice(
        offset,
        offset + itemsPerPage
      )
      let isLastPage = state.staticActionIds.length - offset <= itemsPerPage

      if (isLastPage)
        state.scrollSettings = {
          ...state.scrollSettings,
          allActionsLoaded: true,
          lastPage: true,
        }

      // Filter out any completed actions to get the todo count
      state.plannedActionsCount = state.staticActionIds.filter(
        (id) =>
          !quickActionServiceComputed.completedPlannedActions
            .get()
            ?.some((completedAction) => completedAction.id === id)
      ).length
      if (!state.plannedActionsCount) resetQuickActionService()

      // If actions uptil the current page are not loaded or if current action is the last in loaded actions
      let shouldLoadMore =
        state.plannedActions.length !== page * itemsPerPage ||
        state.currentActionIndex === state.plannedActions?.length - 1

      if (!shouldLoadMore) {
        state.apiStatus = 'success'
        return
      }

      return ActionsApi.getActionsByIds({
        orderBy: getTablePaginationParams(state.scrollSettings, 'orderBy'),
        ids: idsToQuery,
        resourceRequests: [
          'prospect',
          'prospect.phoneNumbers',
          'driveStep',
          'driveState.drive',
        ],
        skipAbort: true,
      }).then((resp) => {
        state.plannedActions = _unionBy(state.plannedActions, resp.data, 'id')
        state.apiStatus = 'success'
      })
    }
  } else {
    const prospectId = pid || router.currentRoute.params.id
    if (prospectId && state.considerProspectPriority) {
      return Promise.all([
        ActionsApi.getActions({ ...options, skipAbort: true }), // the pagination in this reponse would also include the new actions created for the active prospect
        ActionsApi.getActions({
          ...options,
          prospectId,
          skipAbort: true,
          tableOptions: { itemsPerPage: 1000 }, // We are assuming that new actions created on the fly for the active prospect would never be this many
        }),
      ]).then((values) => {
        const totalActions = _unionBy(values[0].data, values[1].data, 'id')
        const resp = { pagination: values[0].pagination, data: totalActions }
        const { page, lastPage } = resp.pagination
        if (page >= lastPage) state.scrollSettings.allActionsLoaded = true
        transformResp(reset, resp, prospectId)
      })
    }
    return ActionsApi.getActions({ ...options, skipAbort: true })
      .then((resp) => transformResp(reset, resp))
      .catch((err) => {
        state.apiStatus = !err.aborted ? 'error' : ''
      })
  }
}

/* internal methods */
const navProspect = (action) => {
  // don't navigate to current prospect
  if (
    router.currentRoute.name === 'ContactView' &&
    router.currentRoute.params.id === action.prospect.id
  )
    return new Promise((resolve) => resolve())

  let opts = {
    name: 'ContactView',
    params: {
      id: `${action.prospect.id}`,
    },
  }

  return router.push(opts)
}

const sendSnackMessage = (msg, severity) => {
  store.commit('snackbar/setSnack', {
    snack: msg,
    snackType: severity,
  })
}

const orderArrayByScrollSettings = (arrayToSort) => {
  const sortDesc = state.scrollSettings.sortDesc[0] ? ['desc'] : ['asc']
  let sortByIteratee = (item) => getLuxonObj(item.due_at)
  return _orderBy(arrayToSort, sortByIteratee, sortDesc)
}
