import api from './Api'

const ENDPOINTS = {
  leadSubscriptions: (id = '') =>
    id ? `/leadSubscriptions/${id}` : `/leadSubscriptions`,
}

export default {
  deleteLeadSubscription(id) {
    return api.delete(ENDPOINTS.leadSubscriptions(id), {
      abortKey: 'DELETE_LEAD_SUBSCRIPTION',
    })
  },
  runSubscription(id) {
    return api.post(`${ENDPOINTS.leadSubscriptions(id)}/run`, {
      abortKey: 'RUN_LEAD_SUBSCRIPTION',
    })
  },
}
