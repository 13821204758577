import UserApi from '@/services/api/Users'
import OrgApi from '@/services/api/Organization'
import CrmApi from '@/services/api/Crm'
import MailboxesApi from '@/services/api/Mailboxes'
import { EventBus } from '@/services/event-bus'
import cubejsApi from '@/services/cubejs/index'

import { clearApiAuthToken, setApiAuthToken } from '@/services/api/Api'
import {
  getCrmIcon,
  getCrmColor,
  getCrmLabel,
  getWarningSeverity,
} from '@/constants'
import UserSettings from '@/constants/UserSettings'
import { getFullName } from '@/utils/helpers'
import {
  HIJACK_LS_KEY,
  setLSItem,
  getLSItem,
  removeLSItem,
} from '@/utils/localStorage'

// initial state
const state = () => ({
  status: '',
  crmStatus: '',
  mailboxesStates: '',
  token: getLSItem('token', false) || '',
  cubeToken: '',
  user: {},
  crm: {},
  phones: [],
  mailboxes: [],
  settings: [],
  crmIframeRequestData: {},
  hijackMode: localStorage.getItem(HIJACK_LS_KEY) || false,
  isHubspotWidget: false, // This will tell Symbo that its rendered as an iframe in Hubspot
  userFetchRequest: false,
})

// getters
const getters = {
  isHubspotWidget: (state) => state.isHubspotWidget,
  isHijackMode: (state) => !!state.hijackMode,
  isLoggedIn: (state) => !!state.token,
  isOrgAdmin: (state) => {
    return state.user && state.user.roles
      ? state.user.roles[0]
        ? ['org_admin', 'symbo_admin'].includes(state.user.roles[0].slug)
        : false
      : false
  },
  isSymboAdmin: (state, getters) =>
    getters.billingPlan?.slug === 'symbo-admin' ||
    state.user?.roles?.[0]?.slug === 'symbo_admin',
  authStatus: (state) => state.status,
  loaded: (state) => !!state.user.id,
  user: (state) => state.user,
  isVerifiedUser: (state) =>
    state.user && (state.user.otp_verified || state.user.otp_override),
  organization: (state) => state.user.organization,
  billingPlan: (state) => state.user?.organization?.billingPlan,
  trialPlanSelected: (state) => state.user?.organization?.trial_plan_selected,

  token: (state) => state.token,
  cubeToken: (state) => state.cubeToken,
  useCsvToken: (state) => state.user?.use_csv_token,

  crm: (state) => state.crm,
  strictCallOutcomesCRM: (state) => state.crm?.config?.strictCallOutcomes,
  noPaginationCrm: (state) => state.crm?.config?.useInfiniteScroll,
  crmName: (state) => getCrmLabel(state.crm.crm_provider),
  crmIcon: (state) => getCrmIcon(state.crm.crm_provider),
  crmColor: (state) => getCrmColor(state.crm.crm_provider),
  phones: (state) => state.phones,
  mailbox: (state) => state.mailboxes[0] || '',
  calendarAvailability: (state, getters) =>
    getters.mailbox?.schedule_access_availability || [],
  allowOtherUsersToSchedule: (state, getters) =>
    getters.mailbox?.allow_other_users_schedule_access || false,

  appWarning: (state, getters) =>
    getters.isHijackMode
      ? { type: 'hijack-mode' }
      : state.user?.organization?.warning,
  appWarningBanner: (state, getters) =>
    getters.isHijackMode ||
    (getters.appWarning &&
      getWarningSeverity(getters.appWarning.type) !== 'blocker'),

  crmMissing: (state) =>
    Object.keys(state.crm).length === 0 && state.crm.constructor === Object,
  phoneMissing: (state) => !state.phones.length,
  mailboxMissing: (state) =>
    state.mailboxes.length === 0 && state.mailboxes.constructor === Array,
  mailboxStatus: (state, getters) => getters.mailbox.status,
  mailboxNeedsAttention: (state, getters) =>
    getters.mailboxStatus?.startsWith('error_'),
  settings: (state) => state.settings,
  getSettingByKey: (state) => (key) => {
    const setting = state.settings.find((setting) => setting.key === key)
    return setting !== undefined ? setting.value : undefined // don't know if this is the best solution
  },
  isFeatureFlagEnabled: (state) => (flag) => {
    return (state.user?.feature_flags || []).includes(flag)
  },
  // loading indicators
  userLoading: (state) => state.status === 'loading',
  crmLoading: (state) => state.status === 'loading',
  mailboxLoading: (state) => state.status === 'loading',

  // User permissions
  canReAssignContact: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_REASSIGN_CONTACT),
  canAccessOtherUserProspects: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_ACCESS_OTHER_USER_PROSPECTS),
  canCreateSequence: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_CREATE_SEQUENCE),
  canArchiveSharedSequence: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_ARCHIVE_SHARED_SEQUENCE),
  canAccessCRMReports: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_ACCESS_CRM_REPORTS),
  canAccessCRMContacts: (state, getters) =>
    getters.isOrgAdmin ||
    getters.getSettingByKey(UserSettings.CAN_ACCESS_CRM_CONTACTS),

  // Feature permissions
  hasCalling: (state, getters) =>
    getters.getSettingByKey(UserSettings.HAS_CALLING),
  hasLocalPresence: (state, getters) =>
    getters.getSettingByKey(UserSettings.HAS_LOCAL_PRESENCE),
  adminCanManageCalls: (state, getters) =>
    getters.getSettingByKey(UserSettings.ADMIN_CAN_MANAGE_CALLS),
  adminCanViewTranscription: (state, getters) =>
    getters.getSettingByKey(UserSettings.ADIMN_CAN_VIEW_TRANSCRIPTION),
  shouldShowCallPages: (state, getters) => {
    if (getters.isOrgAdmin)
      return (
        getters.hasCalling ||
        getters.adminCanManageCalls ||
        getters.adminCanManagePowerDialing
      )
    else return getters.hasCalling
  },
  hasPowerDialing: (state, getters) =>
    getters.isFeatureFlagEnabled('power-dialing') &&
    getters.getSettingByKey(UserSettings.HAS_POWER_DIALING),
  adminCanManagePowerDialing: (state, getters) =>
    getters.isFeatureFlagEnabled('power-dialing') &&
    getters.getSettingByKey(UserSettings.ADMIN_CAN_MANAGE_CALLS),
  userFetchRequest: (state) => state.userFetchRequest,
}

// actions
const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      UserApi.login(user)
        .then((resp) => {
          const token = resp.data.token
          commit('store_login_token', token)
          dispatch('load').then(() => {
            cubejsApi()
            resolve(resp)
            dispatch('pusher/pusherSubscribe', null, { root: true })
          })
        })
        .catch((err) => {
          commit('auth_error')
          removeLSItem('token')
          reject(err)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      EventBus.$emit('user-logout')
      localStorage.clear()
      clearApiAuthToken()
      resolve()
    })
  },

  loginViaCrmToken({ commit }, data) {
    commit('set_crm_sdk_data', data)
    return new Promise((resolve, reject) => {
      commit('auth_request')
      UserApi.login({
        crm_token: data.token,
      })
        .then((resp) => {
          const token = resp.data.token
          commit('store_login_token', token)
          this.dispatch('user/load')
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          removeLSItem('token')
          reject(err)
        })
    })
  },

  load({ commit, state, getters }, forceFetch) {
    commit('setUserFetchRequest', true)
    // On 1st load
    if (getters.userLoading && !forceFetch) return
    if (!state.user?.id) commit('auth_request')

    return new Promise((resolve, reject) => {
      UserApi.getLoggedInUser()
        .then((resp) => {
          commit('store_analytics_auth_token', resp.cubejs_token)
          commit('user_load', resp)
          commit('setUserFetchRequest', false)
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          commit('setUserFetchRequest', false)
          reject(err)
        })
    })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit }) {
    commit('setUserFetchRequest', true)
    return new Promise((resolve, reject) => {
      UserApi.getLoggedInUser(true)
        .then((resp) => {
          commit('store_analytics_auth_token', resp.cubejs_token)
          commit('user_load', resp)
          commit('setUserFetchRequest', false)
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          commit('setUserFetchRequest', false)
          reject(err)
        })
    })
  },

  updateSetting({ commit }, props) {
    if (props.setBeforeResponse) commit('set_setting', props.settings)

    return new Promise((resolve, reject) => {
      commit('auth_request')
      UserApi.updateSetting(props.userId, props.settings)
        .then((resp) => {
          commit('store_settings', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          reject(err)
        })
    })
  },

  updateOrgSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      OrgApi.updateSetting(payload)
        .then((resp) => {
          commit('set_setting', payload)
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          reject(err)
        })
    })
  },

  // used to update the UI first, then update API in background
  setSettingUpdateAsync({ commit }, props) {
    commit('set_setting', props.settings)
    return new Promise((resolve, reject) => {
      commit('auth_request')
      UserApi.updateSetting(props.userId, props.settings)
        .then((resp) => {
          commit('store_settings', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('auth_error')
          reject(err)
        })
    })
  },

  // crm
  getCrm({ commit }) {
    return new Promise((resolve, reject) => {
      commit('crm_status', 'loading')
      CrmApi.getCrm()
        .then((resp) => {
          commit('set_crm', resp)
          commit('crm_status', 'success')
          resolve()
        })
        .catch((err) => {
          commit('crm_status', 'error')
          console.log(err)
          reject(err)
        })
    })
  },

  disconnectCrm({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('crm_status', 'loading')
      CrmApi.delete(state.crm.id)
        .then(() => {
          commit('delete_crm')
          commit('crm_status', 'success')
          resolve()
        })
        .catch((err) => {
          commit('crm_status', 'error')
          console.log(err)
          reject(err)
        })
    })
  },

  // mailboxes
  getMailboxes({ commit }) {
    return new Promise((resolve, reject) => {
      commit('mailboxes_status', 'loading')
      MailboxesApi.getMailboxes()
        .then((resp) => {
          commit('set_mailboxes', resp)
          commit('mailboxes_status', 'success')
          resolve
        })
        .catch((err) => {
          commit('mailboxes_status', 'error')
          console.log(err)
          reject(err)
        })
    })
  },

  disconnectMailbox({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('mailboxes_status', 'loading')
      MailboxesApi.delete(state.mailboxes[0].id)
        .then(() => {
          commit('delete_mailbox')
          commit('mailboxes_status', 'success')
          resolve()
        })
        .catch((err) => {
          commit('mailboxes_status', 'error')
          console.log(err)
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setUserFetchRequest(state, val) {
    state.userFetchRequest = val
  },
  auth_request(state) {
    state.status = 'loading'
  },

  auth_success(state) {
    state.status = 'success'
  },

  auth_error(state) {
    state.status = 'error'
  },

  user_load(state, user) {
    state.status = 'success'
    if (user.mailboxes) {
      state.mailboxes = user.mailboxes
    }
    if (user.crm) {
      state.crm = user.crm
    }
    if (user.phones) {
      state.phones = user.phones
    }
    if (user.settings) {
      state.settings = user.settings
    }
    state.user = {
      ...user,
      full_name: getFullName(user.first_name, user.last_name),
    }
  },

  store_settings(state, settings) {
    state.status = 'success'
    state.settings = settings
  },

  set_setting(state, payload) {
    const index = state.settings.findIndex(
      (setting) => setting.key === payload.settings_key
    )
    if (index !== -1) {
      state.settings = [
        ...state.settings.slice(0, index),
        {
          key: payload.settings_key,
          value: payload.settings_value,
        },
        ...state.settings.slice(index + 1),
      ]
    }
  },

  setHijackMode(state, value) {
    state.hijackMode = value
  },

  // crm
  crm_status(state, status) {
    state.crmStatus = status
  },

  set_crm(state, crm) {
    if (crm) {
      state.crm = crm
    } else {
      state.crm = {}
    }
  },

  set_crm_sdk_data(state, data) {
    state.crmIframeRequestData = {
      token: data.token,
      sdkId: data.sdkId,
    }
  },

  delete_crm(state) {
    state.crm = {}
  },

  // mailboxes
  mailboxes_status(state, status) {
    state.mailboxes_status = status
  },

  set_mailboxes(state, mailboxes) {
    if (mailboxes) {
      state.mailboxes = mailboxes
    } else {
      state.mailboxes = []
    }
    console.log('set_mailboxes', state.mailboxes)
  },

  delete_mailbox(state) {
    state.mailboxes = []
  },

  // phone
  phone_status(state, status) {
    state.phone_status = status
  },

  set_phones(state, phones) {
    if (phones?.length) {
      state.phones = phones
    } else {
      state.phones = []
    }
  },

  store_login_token(state, token) {
    setApiAuthToken(token)
    setLSItem('token', token)
    state.token = token
  },

  store_analytics_auth_token(state, token) {
    state.cubeToken = token
  },

  logout(state) {
    state.status = ''
    state.token = ''
    state.user = {}
    state.crm = {}
    state.phones = []
    state.mailboxes = []
    state.settings = []
  },

  setHubspotWidget(state, newVal) {
    console.log('setHubspotWidget', newVal)
    state.isHubspotWidget = newVal
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
